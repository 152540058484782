.button-primary {
  align-items: center;
  background-color: var(--primary);
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 20px;
  position: relative;
}

.button-primary .contact {
  color: var(--light);
  font-family: var(--button-text-font-family);
  font-size: var(--button-text-font-size);
  font-style: var(--button-text-font-style);
  font-weight: var(--button-text-font-weight);
  letter-spacing: var(--button-text-letter-spacing);
  line-height: var(--button-text-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.button-primary:hover {
  background-color: var(--primary-hovering);
}

.button-primary:hover .contact {
  color: var(--light-hovering);
}
