:root {
  --bg-1: rgba(150, 156, 201, 1);
  --body-bold-font-family: "Trispace-SemiBold", Helvetica;
  --body-bold-font-size: 12px;
  --body-bold-font-style: normal;
  --body-bold-font-weight: 600;
  --body-bold-letter-spacing: 0px;
  --body-bold-line-height: 18px;
  --body-font-family: "Trispace-Light", Helvetica;
  --body-font-size: 12px;
  --body-font-style: normal;
  --body-font-weight: 300;
  --body-letter-spacing: 0px;
  --body-line-height: 18px;
  --button-text-font-family: "TrispaceExpanded-ExtraBold", Helvetica;
  --button-text-font-size: 12px;
  --button-text-font-style: normal;
  --button-text-font-weight: 800;
  --button-text-letter-spacing: 2.4px;
  --button-text-line-height: 18px;
  --display-2-font-family: "TrispaceExpanded-ExtraLight", Helvetica;
  --display-2-font-size: 18px;
  --display-2-font-style: normal;
  --display-2-font-weight: 200;
  --display-2-letter-spacing: 3.6px;
  --display-2-line-height: 18px;
  --header-font-family: "TrispaceExpanded-ExtraBold", Helvetica;
  --header-font-size: 18px;
  --header-font-style: normal;
  --header-font-weight: 800;
  --header-letter-spacing: 0px;
  --header-line-height: 18px;
  --light: rgba(237, 241, 255, 1);
  --light-hovering: rgba(218, 223, 242, 1);
  --light-pressed: rgba(197, 204, 229, 1);
  --primary: rgba(25, 31, 77, 1);
  --primary-hovering: rgba(34, 43, 107, 1);
  --primary-pressed: rgba(45, 55, 138, 1);
  --secondary: rgba(255, 91, 72, 1);
  --secondary-hovering: rgba(240, 76, 58, 1);
  --secondary-pressed: rgba(227, 62, 43, 1);
  --subtitle-font-family: "TrispaceExpanded-ExtraLight", Helvetica;
  --subtitle-font-size: 12px;
  --subtitle-font-style: normal;
  --subtitle-font-weight: 200;
  --subtitle-letter-spacing: 2.4px;
  --subtitle-line-height: normal;
  --white: rgba(255, 255, 255, 1);
}
