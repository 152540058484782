.form-field {
  align-items: center;
  background-color: var(--light);
  display: flex;
  padding: 9px;
  position: relative;
  width: 175.5px;
  color: var(--primary);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}