:root {
  --bg-1: #969cc9;
  --body-bold-font-family: "Trispace-SemiBold", Helvetica;
  --body-bold-font-size: 12px;
  --body-bold-font-style: normal;
  --body-bold-font-weight: 600;
  --body-bold-letter-spacing: 0px;
  --body-bold-line-height: 18px;
  --body-font-family: "Trispace-Light", Helvetica;
  --body-font-size: 12px;
  --body-font-style: normal;
  --body-font-weight: 300;
  --body-letter-spacing: 0px;
  --body-line-height: 18px;
  --button-text-font-family: "TrispaceExpanded-ExtraBold", Helvetica;
  --button-text-font-size: 12px;
  --button-text-font-style: normal;
  --button-text-font-weight: 800;
  --button-text-letter-spacing: 2.4px;
  --button-text-line-height: 18px;
  --display-2-font-family: "TrispaceExpanded-ExtraLight", Helvetica;
  --display-2-font-size: 18px;
  --display-2-font-style: normal;
  --display-2-font-weight: 200;
  --display-2-letter-spacing: 3.6px;
  --display-2-line-height: 18px;
  --header-font-family: "TrispaceExpanded-ExtraBold", Helvetica;
  --header-font-size: 18px;
  --header-font-style: normal;
  --header-font-weight: 800;
  --header-letter-spacing: 0px;
  --header-line-height: 18px;
  --light: #edf1ff;
  --light-hovering: #dadff2;
  --light-pressed: #c5cce5;
  --primary: #191f4d;
  --primary-hovering: #222b6b;
  --primary-pressed: #2d378a;
  --secondary: #ff5b48;
  --secondary-hovering: #f04c3a;
  --secondary-pressed: #e33e2b;
  --subtitle-font-family: "TrispaceExpanded-ExtraLight", Helvetica;
  --subtitle-font-size: 12px;
  --subtitle-font-style: normal;
  --subtitle-font-weight: 200;
  --subtitle-letter-spacing: 2.4px;
  --subtitle-line-height: normal;
  --white: #fff;
}

.button-primary {
  background-color: var(--primary);
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  display: inline-flex;
  position: relative;
}

.button-primary .contact {
  color: var(--light);
  font-family: var(--button-text-font-family);
  font-size: var(--button-text-font-size);
  font-style: var(--button-text-font-style);
  font-weight: var(--button-text-font-weight);
  letter-spacing: var(--button-text-letter-spacing);
  line-height: var(--button-text-line-height);
  text-align: justify;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.button-primary:hover {
  background-color: var(--primary-hovering);
}

.button-primary:hover .contact {
  color: var(--light-hovering);
}

.button-secondary {
  background-color: var(--secondary);
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  display: inline-flex;
  position: relative;
}

.button-secondary .text-wrapper {
  color: var(--light);
  font-family: var(--button-text-font-family);
  font-size: var(--button-text-font-size);
  font-style: var(--button-text-font-style);
  font-weight: var(--button-text-font-weight);
  letter-spacing: var(--button-text-letter-spacing);
  line-height: var(--button-text-line-height);
  text-align: justify;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.button-secondary:hover {
  background-color: var(--secondary-hovering);
}

.button-secondary:hover .text-wrapper {
  color: var(--light-hovering);
}

.form-field {
  background-color: var(--light);
  color: var(--primary);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  text-align: justify;
  white-space: nowrap;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  padding: 9px;
  display: flex;
  position: relative;
}

@font-face {
  font-family: Trispace-SemiBold;
  src: url("Trispace-SemiBold.8695454d.ttf") format("truetype"), ;
}

@font-face {
  font-family: Trispace-Light;
  src: url("Trispace-Light.110a3d64.ttf") format("truetype"), ;
}

@font-face {
  font-family: TrispaceExpanded-ExtraBold;
  src: url("Trispace_Expanded-ExtraBold.9cbb5769.ttf") format("truetype"), ;
}

@font-face {
  font-family: TrispaceExpanded-ExtraLight;
  src: url("Trispace_Expanded-ExtraLight.e16c7579.ttf") format("truetype"), ;
}

.mobile {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  height: 852px;
  display: flex;
  position: relative;
}

.mobile .main {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  margin-bottom: -847.91px;
  display: flex;
  position: relative;
}

.mobile .div-wrapper {
  background-color: var(--primary);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .title-bar-content {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9px;
  width: 100%;
  padding: 9px;
  display: flex;
  position: relative;
}

.mobile .title-bar-logo {
  width: 189.37px;
  height: 54.91px;
  position: relative;
}

.mobile .title-bar-subtitle {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .div {
  color: var(--light);
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Trispace Expanded-Light, Helvetica;
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  position: relative;
}

.mobile .about {
  background-color: var(--white);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .about-picture {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .about-picture-wrapper {
  flex: none;
  align-self: stretch;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.mobile .about-picture-2 {
  background-image: url("about-picture-picture.46a5051c.png");
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 242px;
  position: relative;
  left: -2px;
}

.mobile .about-picture-lines {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3px;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .about-picture-line {
  background-color: var(--secondary);
  align-self: stretch;
  width: 100%;
  height: 3px;
  position: relative;
}

.mobile .about-content {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 21px;
  width: 100%;
  padding: 21px 21px 42px;
  display: flex;
  position: relative;
}

.mobile .about-text-area {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .p {
  color: var(--primary);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  text-align: justify;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.mobile .text-wrapper-2 {
  color: var(--secondary);
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
  text-align: justify;
  align-self: stretch;
  height: 36px;
  position: relative;
}

.mobile .about-contact {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 9px;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .about-button-area {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 9px;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .design-component-instance-node {
  flex: 1 !important;
  display: flex !important;
}

.mobile .text-wrapper-3 {
  color: var(--primary);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.mobile .nieuwsbrief {
  background-color: var(--bg-1);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .div-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 21px;
  width: 100%;
  padding: 42px 21px;
  display: flex;
  position: relative;
}

.mobile .div-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .nieuwsbrief-2 {
  color: var(--primary);
  font-family: var(--header-font-family);
  font-size: var(--header-font-size);
  font-style: var(--header-font-style);
  font-weight: var(--header-font-weight);
  letter-spacing: var(--header-letter-spacing);
  line-height: var(--header-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.mobile .text-wrapper-4 {
  color: var(--light);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  text-align: justify;
  align-self: stretch;
  position: relative;
}

.mobile .nieuwsbrief-form {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 9px;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .form-field-instance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.mobile .button-primary-instance {
  width: 100% !important;
  height: 38px !important;
  display: flex !important;
}

.mobile .text-wrapper-5 {
  color: var(--primary);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  text-align: justify;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.mobile .werkboek-title-area {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .werkboek {
  color: var(--secondary);
  font-family: var(--header-font-family);
  font-size: var(--header-font-size);
  font-style: var(--header-font-style);
  font-weight: var(--header-font-weight);
  letter-spacing: var(--header-letter-spacing);
  line-height: var(--header-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.mobile .vooruitkijken-naar {
  color: var(--light);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.mobile .about-picture-line-2 {
  background-color: var(--secondary);
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.mobile .banner {
  background-color: var(--secondary);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.mobile .banner-content {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 9px;
  width: 100%;
  height: 250px;
  padding: 0 21px;
  display: flex;
  position: relative;
}

.mobile .aan-deze-site-wordt {
  color: var(--primary);
  font-family: var(--header-font-family);
  font-size: var(--header-font-size);
  font-style: var(--header-font-style);
  font-weight: var(--header-font-weight);
  letter-spacing: var(--header-letter-spacing);
  line-height: var(--header-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.mobile .text-wrapper-6 {
  color: var(--light);
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.mobile .enroll-button {
  width: 100%;
}

/*# sourceMappingURL=index.b6ad87e9.css.map */
