@font-face {
  font-family: 'Trispace-SemiBold';
  src: url(/src/assets/Trispace/Trispace-SemiBold.ttf) format('truetype'),
}

@font-face {
  font-family: 'Trispace-Light';
  src: url(/src/assets/Trispace/Trispace-Light.ttf) format('truetype'),
}

@font-face {
  font-family: 'TrispaceExpanded-ExtraBold';
  src: url('/src/assets/Trispace_Expanded/Trispace_Expanded-ExtraBold.ttf') format('truetype'),
}

@font-face {
  font-family: 'TrispaceExpanded-ExtraLight';
  src: url('/src/assets/Trispace_Expanded/Trispace_Expanded-ExtraLight.ttf') format('truetype'),
}


.mobile {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 852px;
  position: relative;
}

.mobile .main {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -847.91px;
  position: relative;
  width: 100%;
}

.mobile .div-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.mobile .title-bar-content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  padding: 9px;
  position: relative;
  width: 100%;
}

.mobile .title-bar-logo {
  height: 54.91px;
  position: relative;
  width: 189.37px;
}

.mobile .title-bar-subtitle {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.mobile .div {
  color: var(--light);
  flex: 1;
  font-family: "Trispace Expanded-Light", Helvetica;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.mobile .about {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.mobile .about-picture {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.mobile .about-picture-wrapper {
  align-self: stretch;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.mobile .about-picture-2 {
  background-image: url(../../../static/img/about-picture-picture.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 242px;
  left: -2px;
  position: relative;
  width: 100%;
}

.mobile .about-picture-lines {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: 100%;
}

.mobile .about-picture-line {
  align-self: stretch;
  background-color: var(--secondary);
  height: 3px;
  position: relative;
  width: 100%;
}

.mobile .about-content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21px;
  padding: 21px 21px 42px;
  position: relative;
  width: 100%;
}

.mobile .about-text-area {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.mobile .p {
  align-self: stretch;
  color: var(--primary);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
}

.mobile .text-wrapper-2 {
  color: var(--secondary);
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  height: 36px;
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
  position: relative;
  text-align: justify;
  align-self: stretch;
}

.mobile .about-contact {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  position: relative;
  width: 100%;
}

.mobile .about-button-area {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.mobile .design-component-instance-node {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
}

.mobile .text-wrapper-3 {
  color: var(--primary);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.mobile .nieuwsbrief {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--bg-1);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.mobile .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21px;
  padding: 42px 21px;
  position: relative;
  width: 100%;
}

.mobile .div-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.mobile .nieuwsbrief-2 {
  align-self: stretch;
  color: var(--primary);
  font-family: var(--header-font-family);
  font-size: var(--header-font-size);
  font-style: var(--header-font-style);
  font-weight: var(--header-font-weight);
  letter-spacing: var(--header-letter-spacing);
  line-height: var(--header-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.mobile .text-wrapper-4 {
  align-self: stretch;
  color: var(--light);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  position: relative;
  text-align: justify;
}

.mobile .nieuwsbrief-form {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.mobile .form-field-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.mobile .button-primary-instance {
  display: flex !important;
  height: 38px !important;
  width: 100% !important;
}

.mobile .text-wrapper-5 {
  color: var(--primary);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.mobile .werkboek-title-area {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 100%;
}

.mobile .werkboek {
  align-self: stretch;
  color: var(--secondary);
  font-family: var(--header-font-family);
  font-size: var(--header-font-size);
  font-style: var(--header-font-style);
  font-weight: var(--header-font-weight);
  letter-spacing: var(--header-letter-spacing);
  line-height: var(--header-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.mobile .vooruitkijken-naar {
  align-self: stretch;
  color: var(--light);
  font-family: var(--subtitle-font-family);
  font-size: var(--subtitle-font-size);
  font-style: var(--subtitle-font-style);
  font-weight: var(--subtitle-font-weight);
  letter-spacing: var(--subtitle-letter-spacing);
  line-height: var(--subtitle-line-height);
  position: relative;
  text-align: center;
}

.mobile .about-picture-line-2 {
  align-self: stretch;
  background-color: var(--secondary);
  height: 1px;
  position: relative;
  width: 100%;
}

.mobile .banner {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.mobile .banner-content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 9px;
  height: 250px;
  justify-content: center;
  padding: 0px 21px;
  position: relative;
  width: 100%;
}

.mobile .aan-deze-site-wordt {
  align-self: stretch;
  color: var(--primary);
  font-family: var(--header-font-family);
  font-size: var(--header-font-size);
  font-style: var(--header-font-style);
  font-weight: var(--header-font-weight);
  letter-spacing: var(--header-letter-spacing);
  line-height: var(--header-line-height);
  position: relative;
  text-align: center;
}

.mobile .text-wrapper-6 {
  color: var(--light);
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.mobile .enroll-button {
  width: 100%;
}

